import React, { ReactElement } from "react";

export default function StorageContainerLogo(): ReactElement {
    return (
        <svg
            width="247"
            height="47"
            viewBox="0 0 247 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M35.2901 29.2699C33.9985 29.2771 32.7295 28.9313 31.6201 28.2699C30.5183 27.6285 29.6389 26.6654 29.1001 25.5099L30.9201 24.0099C31.2743 24.8712 31.8775 25.6073 32.6524 26.1239C33.4273 26.6406 34.3388 26.9142 35.2701 26.9099C36.1492 26.9507 37.0131 26.6698 37.7001 26.1199C37.9941 25.8778 38.2289 25.5718 38.3866 25.2252C38.5443 24.8786 38.6208 24.5005 38.6101 24.1199C38.6101 23.1199 37.9801 22.3099 36.7101 21.5899C36.4901 21.4699 35.8601 21.1799 34.8501 20.6999C34.1662 20.4022 33.4986 20.0684 32.8501 19.6999C32.0486 19.2919 31.372 18.6753 30.8915 17.915C30.411 17.1547 30.1446 16.2789 30.1201 15.3799C30.1046 14.7245 30.2305 14.0735 30.4894 13.4713C30.7482 12.869 31.1339 12.3296 31.6201 11.8899C32.1603 11.4006 32.7916 11.0224 33.4779 10.777C34.1642 10.5317 34.8921 10.4239 35.6201 10.4599C36.6467 10.4449 37.6611 10.6835 38.5734 11.1545C39.4858 11.6255 40.2677 12.3143 40.8501 13.1599L39.2001 14.7299C38.7937 14.1334 38.248 13.6449 37.6102 13.3069C36.9724 12.9689 36.2619 12.7914 35.5401 12.7899C34.7942 12.7629 34.0633 13.0041 33.4801 13.4699C33.217 13.684 33.007 13.9562 32.8664 14.2649C32.7259 14.5737 32.6587 14.9108 32.6701 15.2499C32.6876 15.7436 32.8417 16.2229 33.1154 16.6342C33.389 17.0456 33.7715 17.373 34.2201 17.5799C34.9025 17.9793 35.607 18.3399 36.3301 18.6599C37.3301 19.1499 38.1201 19.5399 38.6201 19.8199C39.4016 20.2157 40.0592 20.8186 40.5213 21.5629C40.9833 22.3071 41.232 23.1639 41.2401 24.0399C41.2644 24.757 41.1216 25.47 40.8231 26.1226C40.5246 26.7751 40.0785 27.3493 39.5201 27.7999C38.3345 28.7857 36.8315 29.308 35.2901 29.2699Z"
                fill="#00A8B1"
            />
            <path
                d="M51.5799 26.3491L52.4599 28.1691C51.4497 28.9256 50.2112 29.3137 48.95 29.2691C48.3709 29.3054 47.7911 29.21 47.2542 28.99C46.7172 28.7701 46.2371 28.4313 45.8499 27.9991C45.069 26.9454 44.6896 25.6476 44.7799 24.3391V17.7991H42.4399V15.7991H44.7999V11.1191H47.1099V15.7891H51.7799V17.7891H47.1099V24.4991C47.1099 26.2391 47.8199 27.0991 49.2499 27.0991C50.0819 27.0746 50.8898 26.8145 51.5799 26.3491V26.3491Z"
                fill="#00A8B1"
            />
            <path
                d="M60.2698 29.2692C59.3437 29.2974 58.4217 29.1344 57.5614 28.7903C56.7011 28.4461 55.921 27.9284 55.2698 27.2692C54.6355 26.6153 54.1374 25.8418 53.8043 24.9938C53.4713 24.1458 53.31 23.2401 53.3298 22.3292C53.3092 21.4149 53.4746 20.5059 53.8159 19.6574C54.1572 18.809 54.6674 18.0387 55.3155 17.3934C55.9636 16.7482 56.7361 16.2413 57.586 15.9037C58.4359 15.566 59.3456 15.4046 60.2599 15.4292C61.1772 15.3996 62.0906 15.5622 62.9414 15.9067C63.7922 16.2511 64.5615 16.7697 65.1999 17.4292C66.4589 18.7559 67.1608 20.5152 67.1608 22.3442C67.1608 24.1732 66.4589 25.9325 65.1999 27.2592C64.5598 27.9143 63.7919 28.4308 62.9438 28.7766C62.0956 29.1224 61.1855 29.29 60.2698 29.2692V29.2692ZM64.8499 22.3492C64.8613 21.7359 64.7504 21.1265 64.5238 20.5564C64.2971 19.9864 63.9593 19.4673 63.5298 19.0292C63.1181 18.5871 62.6197 18.2344 62.0657 17.9933C61.5117 17.7522 60.914 17.6278 60.3099 17.6278C59.7057 17.6278 59.108 17.7522 58.554 17.9933C58 18.2344 57.5016 18.5871 57.0899 19.0292C56.6604 19.4673 56.3226 19.9864 56.0959 20.5564C55.8693 21.1265 55.7584 21.7359 55.7698 22.3492C55.7581 22.9686 55.8687 23.5841 56.0952 24.1607C56.3217 24.7373 56.6597 25.2635 57.0899 25.7092C57.5016 26.1514 58 26.504 58.554 26.7451C59.108 26.9862 59.7057 27.1106 60.3099 27.1106C60.914 27.1106 61.5117 26.9862 62.0657 26.7451C62.6197 26.504 63.1181 26.1514 63.5298 25.7092C63.96 25.2635 64.298 24.7373 64.5245 24.1607C64.751 23.5841 64.8616 22.9686 64.8499 22.3492V22.3492Z"
                fill="#00A8B1"
            />
            <path
                d="M72.36 28.9592H70V15.7892H72.23V17.7292C72.5611 17.0135 73.0992 16.4137 73.7749 16.0072C74.4506 15.6007 75.2327 15.4063 76.02 15.4492C76.5389 15.453 77.0557 15.5168 77.56 15.6392L77.34 17.9292C76.8975 17.7855 76.4353 17.7112 75.97 17.7092C75.4793 17.6886 74.9901 17.7765 74.5372 17.9667C74.0843 18.1568 73.679 18.4445 73.35 18.8092C72.6332 19.7043 72.2766 20.8348 72.35 21.9792L72.36 28.9592Z"
                fill="#00A8B1"
            />
            <path
                d="M89.3501 17.7093V15.7893H91.6101V28.9593H89.3501V27.0893C88.8028 27.7922 88.0973 28.3559 87.291 28.7348C86.4848 29.1136 85.6004 29.2967 84.71 29.2693C83.8273 29.2942 82.9496 29.1285 82.1368 28.7833C81.3239 28.4382 80.5951 27.9218 80.0001 27.2693C78.7407 25.9151 78.0685 24.1175 78.1301 22.2693C78.0959 21.3747 78.244 20.4826 78.5655 19.6471C78.887 18.8116 79.3751 18.0502 80.0001 17.4093C80.6053 16.7739 81.3374 16.2728 82.1488 15.9386C82.9602 15.6043 83.8328 15.4445 84.71 15.4693C85.6044 15.4561 86.4895 15.6516 87.295 16.0405C88.1005 16.4294 88.8042 17.0008 89.3501 17.7093V17.7093ZM89.5001 22.3493C89.5341 21.1157 89.0856 19.9175 88.2501 19.0093C87.8379 18.5556 87.3319 18.1971 86.7672 17.9588C86.2025 17.7205 85.5926 17.608 84.9801 17.6293C84.3717 17.6063 83.7657 17.7181 83.2056 17.9567C82.6455 18.1952 82.145 18.5547 81.7401 19.0093C80.9461 19.9508 80.5107 21.1427 80.5107 22.3743C80.5107 23.6059 80.9461 24.7978 81.7401 25.7393C82.1473 26.1905 82.6484 26.5469 83.2081 26.7836C83.7679 27.0203 84.3727 27.1315 84.9801 27.1093C85.5916 27.1298 86.2004 27.018 86.7647 26.7816C87.3291 26.5451 87.8358 26.1896 88.2501 25.7393C89.0914 24.8147 89.5398 23.5988 89.5001 22.3493V22.3493Z"
                fill="#00A8B1"
            />
            <path
                d="M105.69 17.5102V15.7902H108.02V27.6002C108.065 28.5294 107.918 29.4579 107.59 30.3283C107.261 31.1986 106.758 31.9923 106.11 32.6602C105.423 33.303 104.614 33.8022 103.732 34.1285C102.849 34.4547 101.91 34.6015 100.97 34.5602C98.8804 34.5734 96.8411 33.9181 95.1502 32.6902L96.2602 30.8202C97.6041 31.8564 99.2531 32.419 100.95 32.4202C101.581 32.4524 102.212 32.3584 102.806 32.1438C103.4 31.9291 103.946 31.5982 104.41 31.1702C104.835 30.7623 105.169 30.2688 105.39 29.7224C105.611 29.176 105.713 28.589 105.69 28.0002V26.9202C105.125 27.5896 104.416 28.1227 103.616 28.4794C102.816 28.8361 101.946 29.0073 101.07 28.9802C100.192 29.004 99.3178 28.8436 98.505 28.5095C97.6921 28.1754 96.9581 27.6749 96.3502 27.0402C95.7292 26.3996 95.2422 25.6416 94.9177 24.8106C94.5931 23.9795 94.4376 23.0921 94.4602 22.2002C94.4467 21.3127 94.6115 20.4315 94.945 19.6089C95.2784 18.7863 95.7736 18.039 96.4013 17.4113C97.0289 16.7837 97.7762 16.2885 98.5988 15.955C99.4214 15.6216 100.303 15.4567 101.19 15.4702C102.045 15.4508 102.894 15.6242 103.673 15.9773C104.452 16.3304 105.141 16.8543 105.69 17.5102ZM98.1202 25.5102C98.5351 25.9433 99.0358 26.2852 99.5902 26.5142C100.145 26.7432 100.741 26.8542 101.34 26.8402C101.94 26.8573 102.537 26.7477 103.092 26.5185C103.647 26.2894 104.147 25.9458 104.56 25.5102C104.978 25.0815 105.307 24.5747 105.528 24.0187C105.749 23.4627 105.859 22.8686 105.85 22.2702C105.871 21.6637 105.768 21.0594 105.548 20.4938C105.328 19.9283 104.995 19.4133 104.57 18.9802C104.154 18.5499 103.652 18.2105 103.098 17.9833C102.544 17.7562 101.949 17.6461 101.35 17.6602C100.751 17.6437 100.155 17.7526 99.6007 17.9799C99.0461 18.2072 98.5453 18.5479 98.1302 18.9802C97.7046 19.413 97.3717 19.9279 97.1516 20.4935C96.9316 21.0591 96.829 21.6636 96.8502 22.2702C96.8344 22.8723 96.9385 23.4714 97.1566 24.0328C97.3746 24.5942 97.7022 25.1066 98.1202 25.5402V25.5102Z"
                fill="#00A8B1"
            />
            <path
                d="M123.85 22.1307C123.85 22.2907 123.85 22.6907 123.78 23.3307H113.33C113.461 24.4329 114.015 25.4411 114.875 26.1426C115.735 26.8441 116.834 27.1841 117.94 27.0907C118.77 27.0814 119.593 26.9431 120.38 26.6807C121.153 26.4425 121.882 26.0812 122.54 25.6107L123.54 27.4107C122.761 27.9793 121.897 28.4214 120.98 28.7207C119.987 29.0686 118.942 29.2477 117.89 29.2507C116.798 29.2693 115.713 29.0687 114.7 28.6607C113.846 28.3212 113.093 27.7707 112.51 27.0607C111.974 26.4015 111.558 25.6535 111.28 24.8507C110.998 24.0373 110.856 23.1818 110.86 22.3207C110.803 20.5057 111.465 18.7415 112.7 17.4107C113.324 16.7688 114.075 16.2646 114.905 15.9305C115.735 15.5963 116.626 15.4397 117.52 15.4707C118.366 15.4405 119.209 15.5847 119.997 15.8944C120.785 16.204 121.501 16.6725 122.1 17.2707C122.703 17.9219 123.169 18.6877 123.469 19.5227C123.77 20.3577 123.9 21.2447 123.85 22.1307ZM113.33 21.2707H121.46C121.455 20.7653 121.349 20.266 121.147 19.8025C120.945 19.339 120.652 18.9208 120.286 18.5729C119.919 18.2249 119.486 17.9542 119.013 17.7769C118.539 17.5996 118.035 17.5193 117.53 17.5407C116.52 17.5148 115.538 17.872 114.78 18.5407C113.994 19.2547 113.482 20.2199 113.33 21.2707V21.2707Z"
                fill="#00A8B1"
            />
            <path
                d="M135.31 29.2709C134.087 29.2997 132.87 29.0836 131.732 28.6354C130.593 28.1872 129.555 27.5158 128.68 26.6609C127.79 25.7964 127.091 24.7558 126.626 23.6056C126.161 22.4554 125.941 21.2209 125.98 19.9809C125.938 18.7212 126.161 17.4668 126.635 16.2988C127.109 15.1309 127.823 14.0754 128.73 13.2009C129.603 12.3318 130.641 11.6468 131.783 11.1864C132.926 10.7261 134.149 10.4997 135.38 10.5209C136.972 10.5046 138.543 10.8861 139.95 11.6309C141.278 12.2773 142.407 13.2685 143.22 14.5009L141.4 16.0309C140.738 15.0535 139.842 14.2566 138.795 13.7119C137.747 13.1671 136.581 12.8919 135.4 12.9109C134.488 12.8915 133.583 13.059 132.738 13.4029C131.894 13.7469 131.129 14.2601 130.49 14.9109C129.827 15.5782 129.308 16.374 128.964 17.2493C128.62 18.1246 128.459 19.061 128.49 20.0009C128.458 20.9157 128.614 21.8273 128.95 22.6789C129.285 23.5306 129.792 24.3042 130.44 24.9514C131.087 25.5986 131.86 26.1057 132.712 26.4411C133.564 26.7765 134.475 26.933 135.39 26.9009C136.569 26.9082 137.732 26.6303 138.78 26.0909C139.834 25.5438 140.756 24.7724 141.48 23.8309L143.25 25.3309C142.366 26.5415 141.199 27.5167 139.85 28.1709C138.444 28.8887 136.889 29.2655 135.31 29.2709V29.2709Z"
                fill="#00A8B1"
            />
            <path
                d="M151.85 29.269C150.924 29.2978 150.001 29.135 149.141 28.7909C148.281 28.4467 147.501 27.9286 146.85 27.269C146.207 26.6223 145.699 25.8544 145.356 25.0097C145.013 24.1651 144.841 23.2607 144.85 22.349C144.829 21.4347 144.995 20.5257 145.336 19.6772C145.677 18.8288 146.187 18.0585 146.836 17.4132C147.484 16.7679 148.256 16.2611 149.106 15.9234C149.956 15.5858 150.866 15.4244 151.78 15.449C152.696 15.4183 153.608 15.5805 154.457 15.925C155.307 16.2696 156.074 16.7888 156.71 17.449C157.973 18.7734 158.678 20.5335 158.678 22.364C158.678 24.1945 157.973 25.9546 156.71 27.279C156.077 27.9232 155.32 28.4319 154.485 28.7741C153.649 29.1162 152.753 29.2846 151.85 29.269V29.269ZM156.39 22.349C156.401 21.7357 156.29 21.1262 156.064 20.5562C155.837 19.9862 155.499 19.467 155.07 19.029C154.658 18.5868 154.16 18.2342 153.606 17.9931C153.052 17.752 152.454 17.6276 151.85 17.6276C151.246 17.6276 150.648 17.752 150.094 17.9931C149.54 18.2342 149.042 18.5868 148.63 19.029C148.199 19.4665 147.86 19.9854 147.631 20.5554C147.403 21.1254 147.29 21.735 147.3 22.349C147.29 22.9689 147.402 23.5848 147.631 24.1613C147.859 24.7378 148.198 25.2637 148.63 25.709C149.042 26.1511 149.54 26.5037 150.094 26.7448C150.648 26.986 151.246 27.1104 151.85 27.1104C152.454 27.1104 153.052 26.986 153.606 26.7448C154.16 26.5037 154.658 26.1511 155.07 25.709C155.928 24.8039 156.392 23.5956 156.36 22.349H156.39Z"
                fill="#00A8B1"
            />
            <path
                d="M163.9 28.9595H161.54V15.7895H163.85V17.6595C164.265 16.9323 164.879 16.3383 165.619 15.9469C166.359 15.5556 167.195 15.383 168.03 15.4495C168.711 15.4304 169.39 15.546 170.027 15.7897C170.663 16.0334 171.246 16.4003 171.74 16.8695C172.262 17.4104 172.665 18.0547 172.924 18.7608C173.182 19.4669 173.29 20.2192 173.24 20.9695V28.9695H170.9V21.6395C170.97 20.576 170.643 19.5244 169.98 18.6895C169.679 18.3405 169.304 18.064 168.881 17.8804C168.459 17.6969 168 17.6111 167.54 17.6295C167.046 17.616 166.555 17.71 166.101 17.9051C165.647 18.1003 165.24 18.3918 164.91 18.7595C164.229 19.5689 163.873 20.6023 163.91 21.6595L163.9 28.9595Z"
                fill="#00A8B1"
            />
            <path
                d="M184.29 26.3491L185.17 28.1691C184.16 28.9256 182.921 29.3137 181.66 29.2691C181.076 29.3093 180.491 29.2158 179.948 28.9957C179.406 28.7757 178.921 28.4348 178.53 27.9991C177.754 26.943 177.375 25.647 177.46 24.3391V17.7991H175.13V15.7991H177.49V11.1191H179.79V15.7891H184.46V17.7891H179.79V24.4991C179.79 26.2391 180.51 27.0991 181.93 27.0991C182.772 27.0814 183.592 26.8209 184.29 26.3491V26.3491Z"
                fill="#00A8B1"
            />
            <path
                d="M197.28 17.7091V15.7891H199.54V28.9591H197.28V27.0891C196.733 27.792 196.027 28.3557 195.221 28.7346C194.415 29.1134 193.53 29.2965 192.64 29.2691C191.756 29.2929 190.877 29.1266 190.063 28.7816C189.249 28.4365 188.518 27.9208 187.92 27.2691C186.67 25.9098 186.002 24.1149 186.06 22.2691C186.027 21.3755 186.174 20.4844 186.494 19.6493C186.814 18.8142 187.299 18.0522 187.92 17.4091C188.528 16.7744 189.262 16.2739 190.075 15.9398C190.888 15.6057 191.761 15.4454 192.64 15.4691C193.534 15.4559 194.419 15.6514 195.225 16.0403C196.03 16.4292 196.734 17.0006 197.28 17.7091V17.7091ZM197.43 22.3491C197.464 21.1155 197.016 19.9173 196.18 19.0091C195.768 18.5554 195.262 18.1969 194.697 17.9586C194.132 17.7203 193.523 17.6078 192.91 17.6291C192.302 17.6061 191.696 17.7179 191.136 17.9564C190.575 18.195 190.075 18.5545 189.67 19.0091C188.876 19.9506 188.441 21.1425 188.441 22.3741C188.441 23.6057 188.876 24.7976 189.67 25.7391C190.077 26.1903 190.578 26.5467 191.138 26.7834C191.698 27.0201 192.303 27.1313 192.91 27.1091C193.522 27.1296 194.13 27.0178 194.695 26.7814C195.259 26.5449 195.766 26.1894 196.18 25.7391C197.021 24.8145 197.47 23.5986 197.43 22.3491Z"
                fill="#00A8B1"
            />
            <path
                d="M204.43 13.9206C204.203 13.9267 203.978 13.8874 203.766 13.8049C203.555 13.7225 203.363 13.5986 203.2 13.4406C203.038 13.2843 202.911 13.0959 202.827 12.8875C202.742 12.6791 202.703 12.4554 202.71 12.2306C202.702 12.0103 202.742 11.791 202.827 11.5874C202.911 11.3838 203.039 11.2008 203.2 11.0506C203.532 10.738 203.974 10.5691 204.43 10.5806C204.649 10.5773 204.866 10.6181 205.069 10.7006C205.271 10.7831 205.456 10.9055 205.61 11.0606C205.769 11.2105 205.895 11.392 205.979 11.5936C206.064 11.7951 206.105 12.0122 206.1 12.2306C206.107 12.4554 206.067 12.6791 205.983 12.8875C205.899 13.0959 205.772 13.2843 205.61 13.4406C205.456 13.5958 205.271 13.7182 205.069 13.8007C204.866 13.8832 204.649 13.9239 204.43 13.9206V13.9206ZM205.56 15.7906V28.9606H203.2V15.7906H205.56Z"
                fill="#00A8B1"
            />
            <path
                d="M211.58 28.9595H209.22V15.7895H211.53V17.6595C211.945 16.9323 212.559 16.3383 213.299 15.9469C214.039 15.5556 214.875 15.383 215.71 15.4495C216.39 15.4295 217.067 15.5448 217.702 15.7885C218.337 16.0323 218.918 16.3997 219.41 16.8695C219.932 17.4104 220.335 18.0547 220.594 18.7608C220.852 19.4669 220.96 20.2192 220.91 20.9695V28.9695H218.58V21.6395C218.646 20.5766 218.318 19.5266 217.66 18.6895C217.357 18.3403 216.98 18.0637 216.556 17.8802C216.132 17.6967 215.672 17.611 215.21 17.6295C214.717 17.6161 214.228 17.7102 213.775 17.9054C213.323 18.1005 212.918 18.392 212.59 18.7595C211.905 19.5665 211.548 20.6016 211.59 21.6595L211.58 28.9595Z"
                fill="#00A8B1"
            />
            <path
                d="M236.63 22.1295C236.63 22.2895 236.63 22.6895 236.56 23.3295H226.07C226.201 24.4335 226.756 25.4432 227.619 26.145C228.481 26.8468 229.582 27.1855 230.69 27.0894C231.517 27.0817 232.337 26.9434 233.12 26.6794C233.894 26.4455 234.625 26.0838 235.28 25.6094L236.28 27.4094C235.503 27.9761 234.643 28.4181 233.73 28.7194C232.733 29.0675 231.686 29.2466 230.63 29.2494C229.541 29.2688 228.459 29.0682 227.45 28.6594C226.592 28.3226 225.835 27.7718 225.25 27.0595C224.714 26.4002 224.298 25.6522 224.02 24.8495C223.737 24.036 223.595 23.1805 223.6 22.3194C223.546 20.503 224.211 18.7386 225.45 17.4094C226.072 16.7678 226.821 16.2637 227.649 15.9295C228.478 15.5953 229.367 15.4386 230.26 15.4695C231.108 15.4366 231.953 15.5796 232.743 15.8894C233.533 16.1991 234.25 16.669 234.85 17.2694C235.46 17.9168 235.933 18.6814 236.239 19.5168C236.545 20.3522 236.678 21.2411 236.63 22.1295V22.1295ZM226.07 21.2694H234.2C234.192 20.7613 234.082 20.2599 233.878 19.7945C233.674 19.3292 233.379 18.9093 233.01 18.5595C232.263 17.8806 231.279 17.5216 230.27 17.5595C229.26 17.5335 228.277 17.8908 227.52 18.5595C226.742 19.2713 226.231 20.2274 226.07 21.2694V21.2694Z"
                fill="#00A8B1"
            />
            <path
                d="M241.77 28.9592H239.41V15.7892H241.64V17.7292C241.971 17.0135 242.509 16.4137 243.185 16.0072C243.86 15.6007 244.643 15.4063 245.43 15.4492C245.949 15.453 246.466 15.5168 246.97 15.6392L246.75 17.9292C246.307 17.7855 245.845 17.7112 245.38 17.7092C244.889 17.6886 244.4 17.7765 243.947 17.9667C243.494 18.1568 243.089 18.4445 242.76 18.8092C242.043 19.7043 241.686 20.8348 241.76 21.9792L241.77 28.9592Z"
                fill="#00A8B1"
            />
            <path
                d="M48.1801 35.8406C48.6635 35.8305 49.1436 35.9234 49.5884 36.1131C50.0332 36.3027 50.4326 36.5848 50.7601 36.9406C51.4347 37.678 51.8088 38.6412 51.8088 39.6406C51.8088 40.64 51.4347 41.6032 50.7601 42.3406C50.4294 42.6971 50.0264 42.9789 49.578 43.1669C49.1295 43.3548 48.6461 43.4448 48.16 43.4306C47.6665 43.4428 47.177 43.3376 46.732 43.1238C46.2869 42.9099 45.899 42.5935 45.6001 42.2006V46.2006H44.3V36.0106H45.6001V37.1106C45.8979 36.7087 46.2874 36.3838 46.7361 36.1629C47.1849 35.942 47.6799 35.8315 48.1801 35.8406V35.8406ZM50.5 39.6206C50.5185 38.9408 50.271 38.2806 49.8101 37.7806C49.577 37.5402 49.298 37.349 48.9897 37.2185C48.6813 37.088 48.3499 37.0207 48.015 37.0207C47.6802 37.0207 47.3488 37.088 47.0404 37.2185C46.7321 37.349 46.4531 37.5402 46.22 37.7806C45.7817 38.2973 45.5411 38.953 45.5411 39.6306C45.5411 40.3082 45.7817 40.9638 46.22 41.4806C46.4531 41.721 46.7321 41.9121 47.0404 42.0427C47.3488 42.1732 47.6802 42.2405 48.015 42.2405C48.3499 42.2405 48.6813 42.1732 48.9897 42.0427C49.298 41.9121 49.577 41.721 49.8101 41.4806C50.2759 40.9756 50.5238 40.3072 50.5 39.6206V39.6206Z"
                fill="#F47721"
            />
            <path
                d="M54.6501 43.2691H53.3501V36.0091H54.5801V37.0791C54.7613 36.6854 55.0563 36.355 55.427 36.1304C55.7977 35.9058 56.2272 35.7974 56.6601 35.8191C56.9467 35.8237 57.2318 35.8606 57.5101 35.9291L57.3901 37.1891C57.1475 37.1119 56.8947 37.0715 56.6401 37.0691C56.3708 37.0576 56.1022 37.1053 55.8534 37.209C55.6045 37.3127 55.3815 37.4697 55.2001 37.6691C54.8034 38.1627 54.6071 38.7874 54.6501 39.4191V43.2691Z"
                fill="#F47721"
            />
            <path
                d="M59.2799 34.9791C59.1562 34.9832 59.033 34.962 58.9177 34.9169C58.8025 34.8717 58.6977 34.8035 58.6097 34.7165C58.5217 34.6294 58.4524 34.5254 58.406 34.4106C58.3596 34.2958 58.3371 34.1728 58.3399 34.0491C58.3366 33.9278 58.3589 33.8072 58.4054 33.6952C58.4519 33.5832 58.5216 33.4823 58.6099 33.3991C58.6996 33.3153 58.8049 33.25 58.9199 33.2071C59.0349 33.1642 59.1573 33.1445 59.2799 33.1491C59.3998 33.1464 59.519 33.1677 59.6305 33.2118C59.742 33.2558 59.8436 33.3217 59.9293 33.4055C60.015 33.4894 60.0831 33.5895 60.1296 33.7C60.176 33.8105 60.2 33.9292 60.1999 34.0491C60.2043 34.1734 60.1825 34.2973 60.136 34.4127C60.0894 34.5281 60.0193 34.6325 59.9299 34.7191C59.8455 34.8051 59.7441 34.8726 59.6322 34.9174C59.5203 34.9621 59.4004 34.9831 59.2799 34.9791V34.9791ZM59.9099 35.9791V43.2691H58.6099V36.0091L59.9099 35.9791Z"
                fill="#F47721"
            />
            <path
                d="M65.3 43.4193C64.7914 43.4382 64.2843 43.353 63.8097 43.169C63.3351 42.985 62.9031 42.7061 62.5401 42.3493C61.8512 41.6091 61.4683 40.6355 61.4683 39.6243C61.4683 38.6132 61.8512 37.6396 62.5401 36.8993C62.9129 36.5424 63.3539 36.2646 63.8368 36.0824C64.3197 35.9003 64.8344 35.8176 65.3501 35.8393C66.0504 35.8271 66.7411 36.0033 67.3501 36.3493C67.9231 36.6606 68.3846 37.1431 68.6701 37.7293L67.6001 38.3493C67.3792 37.9366 67.051 37.5911 66.6501 37.3493C66.2694 37.1115 65.8289 36.9866 65.3801 36.9893C65.0369 36.9818 64.6957 37.0444 64.3776 37.1733C64.0595 37.3023 63.7711 37.4949 63.53 37.7393C63.0648 38.2451 62.8066 38.9072 62.8066 39.5943C62.8066 40.2815 63.0648 40.9436 63.53 41.4493C63.7593 41.6954 64.0385 41.8896 64.349 42.0189C64.6594 42.1483 64.9939 42.2098 65.33 42.1993C65.7892 42.1982 66.2391 42.0702 66.6301 41.8293C67.0243 41.6135 67.35 41.2912 67.5701 40.8993L68.6501 41.5193C68.3454 42.0943 67.8751 42.5646 67.3 42.8693C66.6953 43.2298 66.0041 43.4199 65.3 43.4193V43.4193Z"
                fill="#F47721"
            />
            <path
                d="M76.7399 39.5007C76.7399 39.5907 76.7399 39.8107 76.7399 40.1607H70.9699C71.0332 40.7673 71.3289 41.3258 71.795 41.7191C72.261 42.1125 72.8613 42.3102 73.4699 42.2707C73.9259 42.2649 74.3781 42.1873 74.8099 42.0407C75.236 41.9148 75.6382 41.7188 75.9999 41.4607L76.5499 42.4607C76.1248 42.7766 75.6523 43.023 75.1499 43.1907C74.6029 43.3799 74.0286 43.4778 73.4499 43.4807C72.85 43.4928 72.2541 43.3805 71.6999 43.1507C71.2272 42.967 70.8103 42.6638 70.4899 42.2707C70.193 41.911 69.9628 41.5013 69.8099 41.0607C69.6575 40.6131 69.5798 40.1435 69.5799 39.6707C69.5457 38.6713 69.9047 37.6983 70.5799 36.9607C70.9235 36.6093 71.3367 36.3334 71.793 36.1509C72.2493 35.9684 72.7387 35.8832 73.2299 35.9007C73.6962 35.8844 74.1608 35.9648 74.5945 36.1369C75.0282 36.309 75.4216 36.5691 75.7499 36.9007C76.4102 37.6027 76.7661 38.5373 76.7399 39.5007V39.5007ZM70.9299 39.0407H75.3999C75.3988 38.7619 75.3414 38.4863 75.2312 38.2303C75.121 37.9743 74.9602 37.7431 74.7585 37.5507C74.5568 37.3584 74.3183 37.2087 74.0574 37.1108C73.7964 37.0128 73.5184 36.9686 73.2399 36.9807C72.681 36.9742 72.1408 37.1817 71.7299 37.5607C71.3028 37.9488 71.0207 38.4708 70.9299 39.0407V39.0407Z"
                fill="#F47721"
            />
            <path
                d="M80.2999 43.4192C79.753 43.4212 79.2151 43.2798 78.7399 43.0092C78.2575 42.7418 77.8645 42.3384 77.6099 41.8492L78.5399 41.1292C78.7181 41.4811 78.9919 41.7757 79.3299 41.9792C79.6295 42.1687 79.9754 42.2725 80.3299 42.2792C80.6609 42.292 80.986 42.1895 81.2499 41.9892C81.3614 41.9075 81.4522 41.8006 81.5147 41.6773C81.5773 41.5539 81.6099 41.4175 81.6099 41.2792C81.6057 41.0771 81.5404 40.8811 81.4226 40.7168C81.3048 40.5526 81.14 40.4279 80.9499 40.3592C80.8299 40.2992 80.5299 40.1592 80.0399 39.9592C79.5499 39.7592 79.2999 39.6292 79.1499 39.5492C78.8153 39.3963 78.5323 39.1495 78.3353 38.8389C78.1382 38.5282 78.0356 38.1671 78.0399 37.7992C78.0337 37.5244 78.0888 37.2517 78.2013 37.0009C78.3137 36.7501 78.4806 36.5275 78.6899 36.3492C79.1678 35.9428 79.7834 35.7352 80.4099 35.7692C80.867 35.7674 81.3185 35.87 81.7299 36.0692C82.1399 36.2474 82.488 36.5432 82.7299 36.9192L81.8999 37.6992C81.7345 37.4387 81.5053 37.2248 81.2341 37.0778C80.9628 36.9307 80.6584 36.8555 80.3499 36.8592C80.0747 36.848 79.8047 36.9368 79.5899 37.1092C79.4997 37.1872 79.4279 37.2841 79.3795 37.393C79.3311 37.5019 79.3073 37.6201 79.3099 37.7392C79.311 37.9253 79.3691 38.1065 79.4764 38.2584C79.5837 38.4104 79.735 38.5259 79.9099 38.5892L80.7099 38.9392C81.052 39.0688 81.386 39.219 81.7099 39.3892C82.0701 39.5395 82.3785 39.7919 82.5971 40.1153C82.8157 40.4387 82.9348 40.8189 82.9399 41.2092C82.9436 41.5089 82.8799 41.8056 82.7536 42.0773C82.6272 42.3491 82.4414 42.589 82.2099 42.7792C81.6756 43.2238 80.9942 43.4522 80.2999 43.4192Z"
                fill="#F47721"
            />
            <path
                d="M21.9001 42.57L22.2401 2.14L91.6301 9.01L91.6401 7.36L20.3401 0.06L20.2201 0.05L19.7101 0L19.3401 44.46L40.6501 42.73V41.11L21.9001 42.57Z"
                fill="#00A8B1"
            />
            <path
                d="M14.79 4.06055L9.79001 5.37055L9.65001 21.8405L11.11 21.9505C11.1889 21.9504 11.2648 21.9803 11.3224 22.0342C11.38 22.088 11.4149 22.1618 11.42 22.2405V22.3305C11.4153 22.3747 11.4014 22.4174 11.3793 22.4559C11.3572 22.4944 11.3274 22.5279 11.2916 22.5542C11.2559 22.5805 11.2151 22.5991 11.1718 22.6088C11.1284 22.6185 11.0836 22.6191 11.04 22.6105L9.65001 22.4905L9.51001 38.5905L14.45 40.1405L14.79 4.06055Z"
                fill="#F47721"
            />
            <path
                d="M2.77002 36.4601L6.85002 37.7501L6.98003 22.2501L5.46002 22.1201C5.38289 22.1177 5.3094 22.0867 5.25392 22.0331C5.19844 21.9795 5.16496 21.9071 5.16002 21.8301C5.16496 21.7866 5.1785 21.7446 5.19984 21.7064C5.22119 21.6682 5.2499 21.6346 5.28433 21.6077C5.31876 21.5807 5.35821 21.5608 5.40039 21.5492C5.44257 21.5376 5.48663 21.5345 5.53001 21.5401L6.98003 21.6501L7.11002 6.08008L2.98003 7.17008L2.77002 36.4601Z"
                fill="#E16526"
            />
            <path
                d="M9.84003 4.66016L15.6 3.13016L15.29 41.1102L9.55003 39.3002L9.53003 41.5102L17.82 44.2302L18.18 0.160156L9.85003 2.47016L9.84003 4.66016Z"
                fill="#00A8B1"
            />
            <path
                d="M9.21002 4.82039L9.23002 2.65039L7.79002 3.04039L7.77002 5.20039L9.21002 4.82039Z"
                fill="#E16526"
            />
            <path
                d="M7.48997 38.6602L7.46997 40.8302L8.90997 41.3002L8.92997 39.1102L7.48997 38.6602Z"
                fill="#E16526"
            />
            <path
                d="M7.5 37.9408L8.93 38.3908L9.06 22.4308L7.63 22.3008L7.5 37.9408Z"
                fill="#E16526"
            />
            <path
                d="M1.97 36.9207L2.22 6.6707L7.15 5.3707L7.16 3.2207L0.28 5.1307L0 38.3807L6.85 40.6307L6.87 38.4607L1.97 36.9207Z"
                fill="#00A8B1"
            />
            <path
                d="M7.62988 21.6893L9.06988 21.7993L9.20988 5.5293L7.75988 5.9093L7.62988 21.6893Z"
                fill="#E16526"
            />
        </svg>
    );
}
